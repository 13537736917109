import { Action, IResourceItem } from '@refinedev/core';
import { APP_NAME } from '@/config';
import { unslug } from './string';

type Props = {
  resource?: IResourceItem;
  action?: Action;
  params?: Record<string, string | undefined>;
  pathname?: string;
  autoGeneratedTitle: string;
};

export const customTitleHandler = ({ resource, action }: Props) => {
  let title = APP_NAME; // Default title

  if (resource && action) {
    const act = action[0].toUpperCase() + action.slice(1);
    title = `${act} ${resource.label} | ${unslug(title)}`;
  }

  return title;
};
