import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/react';

import { APP_ENVIRONMENT, APP_SENTRY_DSN, APP_SENTRY_RELEASE, APP_SENTRY_SAMPLE_RATE } from '@/config';

export default function initSentry() {
  Sentry.init({
    dsn: APP_SENTRY_DSN ?? '',
    environment: APP_ENVIRONMENT ?? 'preview',
    integrations: [new BrowserTracing()],
    tracesSampleRate: parseFloat(APP_SENTRY_SAMPLE_RATE ?? '1.0'),
    release: APP_SENTRY_RELEASE ?? '',
  });
}
