import type { ResourceProps } from '@refinedev/core';
import type { RouteObject } from 'react-router-dom';
import { dashboardResource, dashboardRoutes } from './pages/dashboard';
import { skuManagementResource, skuManagementRoutes } from './pages/sku-management';
import { vendorResource, vendorRoutes } from './pages/vendor';
import { purchaseOrderResource, purchaseOrderRoutes } from './pages/purchase-order';

export const pagesResources: ResourceProps[] = [
  ...dashboardResource,
  ...vendorResource,
  ...skuManagementResource,
  ...purchaseOrderResource,
];

export const pagesRoutes: RouteObject[] = [dashboardRoutes, vendorRoutes, skuManagementRoutes, purchaseOrderRoutes];
