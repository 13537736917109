import { ENABLE_TOGGLE_DARK_MODE } from '@/config';
import randomAvatar from '@/images/random-avatar.svg';
import type { UserProfile } from '@efishery/sdk-core';
import type { RefineThemedLayoutV2HeaderProps } from '@refinedev/antd';
import { useGetIdentity } from '@refinedev/core';
import { Layout as AntdLayout, Avatar, Space, Switch, Typography, theme } from 'antd';
import React, { useContext } from 'react';
import { ColorModeContext } from '@/utils/contexts/color-mode';

const { Text } = Typography;
const { useToken } = theme;

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({ sticky }) => {
  const { token } = useToken();
  const { data: user } = useGetIdentity<UserProfile>();
  const { mode, setMode } = useContext(ColorModeContext);

  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 24px',
    height: '64px',
    borderBottom: '1px solid var(--color-black-100)',
  };

  if (sticky) {
    headerStyles.position = 'sticky';
    headerStyles.top = 0;
    headerStyles.zIndex = 2;
  }

  return (
    <AntdLayout.Header style={headerStyles}>
      <Space>
        {ENABLE_TOGGLE_DARK_MODE ? (
          <Switch
            checkedChildren="🌛"
            unCheckedChildren="🔆"
            onChange={() => {
              setMode(mode === 'light' ? 'dark' : 'light');
            }}
            defaultChecked={mode === 'light'}
          />
        ) : (
          <Space style={{ marginLeft: '8px' }} size="middle">
            {user?.full_name && <Text strong>{user.full_name}</Text>}
            <Avatar src={randomAvatar} />
          </Space>
        )}
      </Space>
    </AntdLayout.Header>
  );
};
