import { CanAccess } from '@/components/CanAccess';
import { accessControlProvider } from '@/pages/auth/accessControlProvider';
import { Sider } from '@/pages/auth/Sider';
import { pagesResources, pagesRoutes } from '@/routes';
import initSentry from '@/utils/helper/sentry';
import { Typography } from '@efishery/onefish-antd';
import { ErrorComponent, ThemedLayoutV2 } from '@refinedev/antd';
import { Refine, Authenticated as RefineAuthenticated } from '@refinedev/core';
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';
import { Row } from 'antd';
import { Outlet, RouterProvider, createBrowserRouter, type RouteObject } from 'react-router-dom';
import { Header } from './components';
import { authProvider } from './pages/auth/authProvider';
import brandDataProvider, { brandResourceName } from './pages/sku-management/brand/providers/brandServiceProvider';
import categoryDataProvider, {
  categoryResourceName,
} from './pages/sku-management/category/providers/categoryServiceProvider';
import coreSupportDataProvider, { coreSupportResourceName } from './components/core-support/coreSupportServiceProvider';
import enumTagVendorDataProvider, { enumTagVendorResourceName } from './components/enum-tag-vendor-select/provider';
import enumTagDataProvider, {
  enumTagResourceName,
} from './pages/sku-management/enum-tag/providers/enumTagServiceProvider';
import paymentDataProvider, { paymentResourceName } from './components/payment-bank-select/provider';
import vendorBillDataProvider, {
  vendorBillResourceName,
} from './pages/purchase-order/providers/vendorBillServiceProvider';
import purchaseOrderDataProvider, {
  purchaseOrderResourceName,
} from './pages/purchase-order/providers/purchaseOrderServiceProvider';
import purchaseOrderSessionStorageServiceProvider, {
  purchaseOrderSessionStorageResourceName,
} from './pages/purchase-order/providers/purchaseOrderSessionStorageServiceProvider';
import skuProductDetailDataProvider, {
  skuProductDetailResourceName,
} from './pages/sku-management/sku/providers/skuProductDetailServiceProvider';
import skuDataProvider, { skuResourceName } from './pages/sku-management/sku/providers/skuServiceProvider';
import skuSyncDataProvider, { skuSyncResourceName } from './pages/sku-management/sku/providers/skuSyncServiceProvider';
import uomDataProvider, { uomResourceName } from './pages/sku-management/uom/providers/uomServiceProvider';
import vendorPksDataProvider, { vendorPksResourceName } from './pages/vendor/providers/vendorPksServiceProvider';
import vendorSkuDataProvider, { vendorSkuResourceName } from './pages/vendor/providers/vendorSkuServiceProvider';
import vendorStatusDataProvider, {
  vendorStatusResourceName,
} from './pages/vendor/providers/vendorStatusServiceProvider';
import vendorRiskVerificationDataProvider, {
  vendorRiskVerificationDataProviderName,
} from './pages/vendor/providers/vendorRiskVerificationServiceProvider';
import vendorDashmershSetupProvider, {
  vendorDashmershSetupProviderName,
} from './pages/vendor/providers/vendorDashmerchSetupServiceProvider';
import vendorDataProvider, { vendorResourceName } from './pages/vendor/providers/vendorServiceProvider';
import wmsDataProvider, { wmsResourceName } from './components/wms-select/provider';
import { authRoutes } from './pages/auth';
import { useNotificationProvider } from './providers';
import { customTitleHandler } from './utils/helper/title';
import inboundRequestDataProvider, {
  inboundRequestResourceName,
} from './pages/purchase-order/providers/inboundRequestServiceProvider';
import vendorSkuEbpDataProvider, {
  vendorSkuEbpResourceName,
} from './pages/vendor/providers/VendorSkuEbpServiceProvider';
import defaultDataProvider from './providers/defaultDataProvider';
import { ENABLE_FEATURE_MAINTENANCE } from './config';
import MaintenancePage from './pages/maintenance';

const { Text } = Typography;

initSentry();

const Root = () => {
  const [notificationProvider, contextHolder] = useNotificationProvider();

  return (
    <Refine
      dataProvider={{
        default: defaultDataProvider,
        [brandResourceName]: brandDataProvider,
        [skuResourceName]: skuDataProvider,
        [wmsResourceName]: wmsDataProvider,
        [uomResourceName]: uomDataProvider,
        [enumTagResourceName]: enumTagDataProvider,
        [categoryResourceName]: categoryDataProvider,
        [paymentResourceName]: paymentDataProvider,
        [vendorResourceName]: vendorDataProvider,
        [vendorStatusResourceName]: vendorStatusDataProvider,
        [vendorPksResourceName]: vendorPksDataProvider,
        [vendorSkuResourceName]: vendorSkuDataProvider,
        [coreSupportResourceName]: coreSupportDataProvider,
        [skuProductDetailResourceName]: skuProductDetailDataProvider,
        [skuSyncResourceName]: skuSyncDataProvider,
        [purchaseOrderResourceName]: purchaseOrderDataProvider,
        [enumTagVendorResourceName]: enumTagVendorDataProvider,
        [vendorBillResourceName]: vendorBillDataProvider,
        [purchaseOrderSessionStorageResourceName]: purchaseOrderSessionStorageServiceProvider,
        [vendorRiskVerificationDataProviderName]: vendorRiskVerificationDataProvider,
        [inboundRequestResourceName]: inboundRequestDataProvider,
        [vendorDashmershSetupProviderName]: vendorDashmershSetupProvider,
        [vendorSkuEbpResourceName]: vendorSkuEbpDataProvider,
      }}
      notificationProvider={notificationProvider}
      routerProvider={routerBindings}
      authProvider={authProvider}
      accessControlProvider={accessControlProvider}
      resources={pagesResources}
      options={{
        syncWithLocation: true,
        warnWhenUnsavedChanges: true,
      }}
    >
      <Outlet />
      <UnsavedChangesNotifier />
      <DocumentTitleHandler handler={customTitleHandler} />
      {contextHolder}
    </Refine>
  );
};

type AuthenticatedProps = {
  children: React.ReactNode;
};

const Authenticated = ({ children }: AuthenticatedProps) => {
  return (
    <RefineAuthenticated fallback={<CatchAllNavigate to="/login" />}>
      <ThemedLayoutV2
        Header={() => {
          return <Header sticky />;
        }}
        Sider={(props) => {
          return <Sider {...props} fixed />;
        }}
      >
        {children}
      </ThemedLayoutV2>
    </RefineAuthenticated>
  );
};

const AuthenticatedLayout = () => {
  return (
    <Authenticated>
      <Outlet />
    </Authenticated>
  );
};

const ProtectedLayout = () => {
  return (
    <Authenticated>
      <CanAccess
        fallback={
          <Row align="middle" justify="center" style={{ height: '100dvh' }}>
            <Text>No Access</Text>
          </Row>
        }
      >
        <Outlet />
      </CanAccess>
    </Authenticated>
  );
};

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: <NavigateToResource />,
      },
      {
        element: <ProtectedLayout />,
        children: pagesRoutes,
      },
      {
        element: (
          <RefineAuthenticated fallback={<Outlet />}>
            <NavigateToResource />
          </RefineAuthenticated>
        ),
        children: authRoutes,
      },
      {
        element: <AuthenticatedLayout />,
        children: [
          {
            path: '*',
            element: <ErrorComponent />,
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes);

const App = () => {
  const isMaintenance = String(ENABLE_FEATURE_MAINTENANCE);

  if (isMaintenance === 'true') {
    return <MaintenancePage />;
  }

  return <RouterProvider router={router} />;
};

export default App;
